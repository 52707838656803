<script setup lang="ts">
import StarRating from 'vue-star-rating'
import { serviceCreationStore } from "~/stores/serviceCreation";
import { CollectGraphql, CollectStatus } from "~/types/graphql-backend-types/gql-types";
import { statusColor } from "~/utils/status";
import { PropType } from "vue";
import { Box, Calendar } from "lucide-vue-next";

const props = defineProps({
  collect: {
    type: Object as PropType<CollectGraphql>,
    required: true,
    default: null,
  },
});

const { updateServiceProducer } = useCollectsStore();
const { getUsersAsOptions, isProducer, isRecycler } = storeToRefs(useUsersStore())

const { collect } = toRefs(props)
const showRateCollectModal = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const modalStatus = ref<boolean>(false);
const isEditingService = ref<boolean>(false);
const selectedStatus = ref<CollectStatus>() as Ref<CollectStatus>;
const selectedReferent = ref<string>("")

const data = reactive({
  service: {} as CollectGraphql,
  notes: "",
  referent: [],
  referentOptions: <any>[]
});

const path = useRoute().path;

const router = useRouter();
const { t } = useI18n();

function editService(id: any) {
  if (selectedStatus.value === CollectStatus.PickupConfirmed || selectedStatus.value === CollectStatus.Confirmed) {
    serviceCreationStore().setScope(5, 6);
    serviceCreationStore().setStep(5);
  }
  serviceCreationStore().setIsEditing(true);
  router.push(`/waste-collection/${id}`);
}

async function deleteService(id: string) {
  await updateServiceProducer({ id, status: CollectStatus.WpCancel });
  selectedStatus.value = CollectStatus.WpCancel;
  modalStatus.value = false;
}

async function revertCancellation() {
  await updateServiceProducer({ id: data.service?.id, status: CollectStatus.RequestSent });
  selectedStatus.value = CollectStatus.RequestSent;
}

function isNotNullOrEmptyArray(obj: any) {
  return !(obj === null || (Array.isArray(obj) && obj.length === 0));
}
function navigateToCollect() {
  return path === '/' ? router.push({ name: 'collects-collectId', params: { collectId: collect.value.id } }) : null
}

watch(
  () => collect,
  (newService) => {
    data.service = newService;
    selectedStatus.value = collect.value.status;

    const referent = getUsersAsOptions.value;
    data.referentOptions = referent;
    selectedReferent.value = collect?.value.data?.referent;

    data.notes = collect?.value.data?.notes;
  },
  { deep: true, immediate: true },
);
</script>
<template>
  <div v-if="!isLoading && collect">
    <div
      v-if="collect?.collectedContainers?.collection?.length > 0"
      class="flex items-start gap-4 p-4 bg-white border border-[#F5F5F5] rounded-lg"
      @click.prevent="navigateToCollect()"
    >
      <img
        v-if="
          collect?.collectedContainers?.collection[0]?.material?.documents.collection[0]?.signedUrl
        "
        class="w-11 h-11 rounded object-cover"
        :src="collect?.collectedContainers.collection[0]?.material?.documents.collection[0]?.signedUrl"
        :alt="collect?.collectedContainers.collection[0]?.material?.name"
      />
      <img
        v-else-if="
          isNotNullOrEmptyArray(
            collect?.collectedContainers.collection[0]?.material?.documents.collection,
          )
        "
        class="w-11 h-11 rounded"
        :src="
          collect?.collectedContainers.collection[0]?.material?.documents.collection[0]?.signedUrl
        "
        alt="placeholder"
      />
      <Box v-else :size="28" />
      <div class="flex flex-col w-full items-start gap-2">
        <div class="flex justify-between w-full">
          <div class="flex items-center gap-2">
            <p class="font-semibold text-[#262626]">
              {{ collect?.collectedContainers?.collection[0]?.material?.name }}
            </p>
            <div class="flex items-center gap-2">
              <p
                v-for="container in collect.collectedContainers.collection"
                class="flex gap-1 items-center"
              >
                <span class="bg-[#F2F2FF] text-[#3019BE] px-1.5 py-0.2 rounded text-sm whitespace-nowrap">
                  {{
                    container?.material?.name
                      ? `${container.material?.name}`
                      : `${t("global.bin")} ${container?.quantity} L`
                  }}
                </span>
              </p>
            </div>
          </div>

          <div class="flex items-center flex-row gap-1">
            <StarRating 
              v-if="collect.npsRating" 
              v-model:rating="collect.npsRating.rating" 
              @click="showRateCollectModal = true" 
              :star-size="16" 
              :show-rating="false" 
              read-only
              class="cursor-pointer"
            />

            <OptionsDropdown
              v-if="isProducer"
              v-model="selectedStatus"
              @cancel-service="selectedStatus !== CollectStatus.WpCancel ? (modalStatus = true) : revertCancellation()"
              @edit-service="editService(collect.id)"
            />
          </div>
        </div>

        <div class="text-sm font-normal text-[#262626] flex gap-1.5">
          <p>{{ $t("global.fill_rate") }} :</p>
          <p v-for="container in collect?.collectedContainers?.collection">{{ container?.fillRate }} %</p>
        </div>
        <div v-if="isRecycler" class="text-sm font-normal text-[#262626] flex gap-1.5">
          <p>{{ $t("global.site") }} :</p>
          <!-- <p>{{ data.service.wp_site.name }}</p> -->
          <p>{{ collect?.client?.sites?.collection[0]?.name }}</p>
        </div>
        <div class="flex gap-2 text-[#8C8C8C] text-sm w-full">
          <div class="flex gap-1 items-center">
            <Calendar :size="18" />
            <span>
              {{
                new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                  timeZone: "UTC",
                }).format(new Date(collect.startsAt))
              }}
              -
              {{
                new Intl.DateTimeFormat("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                  timeZone: "UTC",
                }).format(new Date(collect.endsAt))
              }}
            </span>
          </div>
        </div>

        <div class="flex items-center gap-4 justify-between w-full">
          <div class="w-full">
            <div v-if="selectedStatus === CollectStatus.WpCancel" class="flex items-center gap-2">
              <Tag tag="Cancellation requested" class="bg-red-200 text-red-800" />
            </div>

            <div v-else-if="collect.status" class="flex items-center justify-between w-full gap-4">
              <p
                v-if="
                  collect.collectedContainers?.collection[0]?.material?.activePricing?.price
                "
              >
                <span class="font-bold text-sm">
                  +
                  {{
                    new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                      collect.collectedContainers?.collection[0]?.material?.activePricing?.price,
                    )
                  }}
                </span>
                <span class="text-sm text-[#9CA3AF]"> / {{ $t("global.per_ton") }} </span>
              </p>
              <div v-else />

              <Tag
                :tag="t(`collect.status_${selectedStatus}`)"
                :tag-classes="statusColor.find((el) => el.status === selectedStatus)"
              />
            </div>
          </div>
        </div>
        <template v-if="isEditingService">
          <div class="flex flex-col gap-0.5 w-full max-w-[600px]">
            <p class="text-[#27272A] text-sm">{{ t("global.referent") + " :" }}</p>
            <WSelect
              id="referent"
              :options="data.referentOptions"
              v-model="selectedReferent"
            />
          </div>

          <div class="flex flex-col gap-0.5 w-full max-w-[600px]">
            <p class="text-[#27272A] text-sm">{{ t("global.edit_note") + " :" }}</p>
            <textarea class="textarea" v-model="data.notes" rows="4" />
          </div>
        </template>
      </div>
    </div>
    <CancelCollectModal v-model="modalStatus" :collect="collect"/>
    <RateCollectNpsModal 
      v-if="collect.npsRating" 
      v-model="showRateCollectModal" 
      v-model:rating="collect.npsRating.rating"
      v-model:description="collect.npsRating.additionalDetails"
      :collect-id="collect.id" 
    />
  </div>
</template>
