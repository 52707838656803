<script setup lang="ts">
import type { ModelRef } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { TagIcon } from 'lucide-vue-next'
import { CollectStatus } from '~/types/graphql-backend-types/gql-types'
import { statusColor } from '~/utils/status'

const status: ModelRef<CollectStatus[]> = defineModel() as ModelRef<CollectStatus[]>

const statuses: { value: CollectStatus }[] = [
  { value: CollectStatus.RequestSent },
  { value: CollectStatus.Confirmed },
  { value: CollectStatus.InTreatment },
  { value: CollectStatus.AdminInProgress },
  { value: CollectStatus.HasIncident },
  { value: CollectStatus.Finished },
  { value: CollectStatus.Cancelled },
  { value: CollectStatus.WpCancel },
]

const isStatusFiltered = computed(() => {
  return (s: CollectStatus) => {
    return status.value.includes(s)
  }
})

function addStatusToFilteredStatuses(s: CollectStatus) {
  if (status.value.includes(s)) {
    status.value = status.value.filter(st => st !== s)
    return
  }
  status.value.push(s)
}
</script>

<template>
  <Popover v-slot="{ open }" class="relative flex-shrink-0">
    <PopoverButton
      class="h-full gap-1.5 px-2 py-1 text-sm btn-secondary"
      :class="open ? '!border-[#373FEF]' : '!hover:text-secondary'"
    >
      <TagIcon :size="18" color="#40403F" />
      <div class="flex items-center gap-1 text-[#262626]">
        <p class="font-semibold">
          {{ `${$t("global.status", status.length)} :` }}
        </p>
        <p v-if="status.length === 0 ">
          {{ $t("global.all") }}
        </p>

        <div
          v-for="(s, index) in status"
          v-else
          :key="index" class="flex items-center gap-2"
        >
          <Tag
            v-if="index <= 1"
            :tag="$t(`collect.status_${s}`)"
            :tag-classes="statusColor[index]"
            class="h-5 px-2 py-1 text-sm"
          />
          <p v-else-if="status.length > 2 && index === 2" class="ml-2 text-zinc-400">
            {{ `+${status.length - 2}` }}
          </p>
        </div>
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel class="absolute left-0 top-9 z-10 max-w-50 w-screen flex">
        <div
          class="w-50 flex-auto overflow-hidden rounded bg-white px-3 py-1 text-sm leading-2 ring-1 ring-gray-900/5"
          style="box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15)"
        >
          <div class="flex flex-col py-2 gap-1">
            <template v-for="s in statuses" :key="s.value">
              <div class="flex items-center gap-2">
                <input
                  :id="`checkbox${s.value}`"
                  :checked="isStatusFiltered(s.value)"
                  type="checkbox"
                  class="custom-checkbox"
                  @click="addStatusToFilteredStatuses(s.value)"
                >
                <label :for="`checkbox${s.value}`">
                  {{ $t(`collect.status_${s.value}`) }}
                </label>
              </div>
            </template>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
