<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { UserCircle } from 'lucide-vue-next'
import { filterStore } from '~/stores/filter'
import { orgStore } from '~/stores/org'

const model = defineModel()

const data = reactive({
  clients: [] as string[],
})

const isStatusFiltered = computed(() => {
  return (status: string) => {
    return data.clients.includes(status)
  }
})

const clients = computed(() => {
  return orgStore().getOrgs?.clients?.filter((client: any) => client.client_type !== 'recycler')
})

function updateModelValue() {
  model.value = data.clients
}

function addClientToFilteredClients(client: string) {
  if (data.clients.includes(client)) {
    data.clients = data.clients?.filter(s => s !== client)
    updateModelValue()
    return
  }
  data.clients.push(client)
  updateModelValue()
}

onMounted(() => {
  data.clients = filterStore().getFilter('client')
})
</script>

<template>
  <Popover v-slot="{ open }" class="relative flex-shrink-0">
    <PopoverButton
      class="h-full gap-1.5 px-2 py-1 text-sm btn-secondary"
      :class="open ? '!border-[#373FEF]' : '!hover:text-secondary'"
    >
      <UserCircle color="#40403F" :size="18" />
      <div class="flex items-center gap-1 text-[#262626]">
        <p class="font-semibold">
          {{ `${$t("global.client", data.clients.length)} :` }}
        </p>
        <p v-if="data.clients.length === 0">
          {{ $t("global.all") }}
        </p>

        <div v-for="(client, index) in data.clients" v-else :key="index" class="flex items-center gap-2">
          <Tag
            v-if="index <= 1"
            :tag="$t(`${clients.find((c: any) => c.id === client)?.name}`)"
            class="h-5 px-2 py-1 text-sm"
          />
          <p v-else-if="data.clients.length > 2 && index === 2" class="ml-2 text-zinc-400">
            {{ `+${data.clients.length - 2}` }}
          </p>
        </div>
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel class="absolute left-0 top-9 z-10 max-w-60 w-screen flex">
        <div
          class="w-50 flex-auto overflow-hidden rounded bg-white px-3 py-1 text-sm leading-2 ring-1 ring-gray-900/5"
          style="box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15)"
        >
          <div class="flex flex-col py-2 gap-1">
            <template v-for="client in clients" :key="client.name">
              <div class="flex items-center gap-2">
                <input
                  :id="`checkbox${client.name}`"
                  :checked="isStatusFiltered(client.id)"
                  type="checkbox"
                  class="custom-checkbox"
                  @click="addClientToFilteredClients(client.id)"
                >
                <label :for="`checkbox${client.name}`" class="whitespace-nowrap">
                  {{ client.name }}
                </label>
              </div>
            </template>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
