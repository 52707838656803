import { gql } from '@apollo/client/core'

export const COLLECTS_QUERY_SINGLE = gql`
query BSDs($pagination: PaginationArgs!, $filters: CollectGraphqlFilters) {
  collects(pagination: $pagination, filters: $filters) {
    collection {
        npsRating {
          additionalDetails
          rating
        }
        documents {
          collection {
            signedUrl
            name
            type
            id
            extension
          ...on CollectDocumentGraphql {
            __typename
            subtype
          }
        }
      }
      status
      BSDs {
        count
        collection {
          id
          tdState
          state
        }
      }
      client {
        name
        id
        sites {
          collection {
            id
            name
            client {
              id
              name
            }
          }
        }
      }
      co2Amount
      collectedContainers {
        collection {
          id
          documents {
            collection {
              signedUrl
              name
              type
              id
              extension
            }
          }
          container {
            name
          }
          material {
            documents {
              collection {
                signedUrl
                name
                type
                id
                extension
              }
            }
            capCode
            consistency
            id
            isSold
            name
            wasteCode
            activePricing {
              id
              unit
              vat
              price
            }
            price
            previousPricings {
              collection {
                price
                unit
                vat
                id
              }
            }
          }
          quantity
          treatmentCode
          unit
          fillRate
        }
      }
      destination {
        address
        id
        zipCode
        name
        client {
          address {
            city
            postalCode
          }
        }
      }
      id
      incidents {
        collection {
          documents {
              collection {
                signedUrl
                name
                type
                id
                extension
              }
            }
          comment
          id
          penaltyAmount
          penaltyComment
          photoUrl
          type
          createdAt
          updatedAt
        }
      }
      endsAt
      licensePlate
      location {
        address
        id
        name
      }
      transportPrice
      startsAt
      recycler {
        address {
          city
          postalCode
          line1
        }
        id
        sites {
          collection {
            address
            name
            id
            client {
              id
              name
            }
          }
        }
      }
    }
  }
}
`

export const COLLECTS_QUERY_LIST = gql`
query BSDs($pagination: PaginationArgs!, $filters: CollectGraphqlFilters) {
  collects(pagination: $pagination, filters: $filters) {
    count
    collection {
      npsRating {
        additionalDetails
        rating
      }
      status
      id
      startsAt
      endsAt
      co2Amount
      createdAt
      client {
        name
        id
      }
      collectedContainers {
        collection {
          id
          material {
            capCode
            consistency
            id
            isSold
            name
            wasteCode
            documents {
              collection {
                signedUrl
              }
            }
            activePricing {
              id
              unit
              vat
              price
            }
          }
          quantity
          treatmentCode
          unit
          fillRate
        }
      }
    }
  }
}
`

export const BO_COLLECTS_QUERY_LIST = gql`
query BSDs($pagination: PaginationArgs!, $filters: CollectGraphqlFilters) {
  collects(pagination: $pagination, filters: $filters) {
    count
    collection {
      status
      createdAt
      client {
        name
        id
      }
      co2Amount
      collectedContainers {
        collection {
          id
          material {
            id
            name
            price
          }
          quantity
          container {
            name
          }
        }
      }
      id
      endsAt
      licensePlate
      transportPrice
      startsAt
      recycler {
        address {
          city
          postalCode
          line1
        }
        id
        name
      }
    }
  }
}
`

export const CANCEL_COLLECT = gql`
mutation Mutation($input: CancelCollectInputGraphql!) {
  cancelCollect(input: $input) {
    status
    id
  }
}
`

export const RATE_COLLECT_NPS = gql`
mutation Mutation($input: RateCollectNPSInputGraphql!) {
  rateCollectNPS(input: $input) {
    id
    npsRating {
      additionalDetails
      rating
    }
  }
}
`
