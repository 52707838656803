import { gql } from '@apollo/client/core'

export const USERS_NEST_QUERY_LIST = gql`
query Users($pagination: PaginationArgs, $filters: UserFilterGraphql) {
  users(pagination: $pagination, filters: $filters) {
    count
    collection {
      role
      lastName
      id
      firstName
      email
      function
      disabled
      client {
        id
      }
    }
  }
}
`

export const GET_CURRENT_USER = gql`
  query Client {
    me {
      client {
        __typename
        id
        isConnectedToTrackdechets
        autosignEnabledForTrackdechets
        documents {
          collection {
            extension
            id
            name
            signedUrl
            type
          }
        }
        sites {
          collection {
            address
            id
            name
            zipCode
          }
          count
        }
      }
      user {
        email
        firstName
        id
        lastName
        role
      }
    }
  }
`

export const TOGGLE_TRACKDECHETS_AUTOSIGN = gql`
  mutation ToggleTrackdechetsAutosign {
    toggleTrackdechetsAutosign {
      autosignEnabledForTrackdechets
      id
      isConnectedToTrackdechets
      name
      sites {
        collection {
          address
          id
          name
          zipCode
        }
      }
      slug
    }
  }
`
