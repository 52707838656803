<script setup lang="ts">
import { Search } from 'lucide-vue-next'

const props = defineProps({
  placeholder: {
    type: String,
    required: false,
  },
  class: {
    type: String,
    required: false,
  },
})

const model = defineModel<string>()
const { t } = useI18n()

const data = reactive({
  searchedValue: '',
})

const debouncedEmit = (() => {
  let timer: any = null
  return (value: string) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      model.value = value.toLowerCase()
    }, 400)
  }
})()

function handleInput() {
  debouncedEmit(data.searchedValue)
}

function loadData() {
  if (model.value)
    data.searchedValue = model.value
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <div class="relative w-full flex items-center text-primary">
    <input
      v-model="data.searchedValue"
      :placeholder="props.placeholder ?? t('global.search')"
      class="w-full flex cursor-pointer items-center gap-2 border border-[#D9D9D9] rounded py-1.5 pl-4 pr-8 text-sm text-[#262626] transition focus-visible:border-[#1BA774] hover:border-[#1BA774] placeholder:text-[#8C8C8C]"
      :class="props.class"
      @input="handleInput"
    >
    <div class="absolute right-0 h-full flex items-center border-l px-2 py-[7px] text-[#262626]">
      <Search :size="18" />
    </div>
  </div>
</template>
