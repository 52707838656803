import { i18n } from '~/modules/i18n'

const t = i18n.global.t as any

export async function loadStores() {
  const { loadUsersNest, getCurrentUser } = useUsersStore()
  const { stores_loaded, stores_loading_msg } = storeToRefs(useUsersStore())
  stores_loaded.value = false
  await loadUsersNest({})
  stores_loading_msg.value = t('stores.users_loading')
  await getCurrentUser()
  stores_loading_msg.value = t('stores.data_loading')
  await loadAppStores()
}

async function loadAppStores() {
  const { loadServices } = useCollectsStore()
  const { loadTreatmentCodes, loadWasteCodes } = useCodeValidationStore()
  const { fetchClients } = useClientsStore()
  const { stores_loaded } = storeToRefs(useUsersStore())

  const storesPromises = []
  await orgStore().loadOrg()
  storesPromises.push(
    sitesStore().loadSites(),
    rawMaterialsContainersStore().loadRawMaterialsContainers(),
    loadServices(),
    priceListStore().loadPricesLists(),
    loadTreatmentCodes(),
    loadWasteCodes(),
    fetchClients(),
  )
  await Promise.all(storesPromises)

  stores_loaded.value = true
}
