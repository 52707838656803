import { omitBy } from 'lodash'
import apolloClient from '~/composables/graphql'
import { useGqlMikro } from '~/composables/useGqlMikro'
import type {
  ClientFilterGraphql,
  ClientGraphql,
  ClientInput,
  CreateClientInput,
  Maybe,
  ProducerGraphql,
  QueryClientsArgs,
  RecyclerGraphql,
} from '~/gql-types/gql-types'
import { GET_CLIENTS, UPDATE_CLIENT } from '~/queries/client'
import { CREATE_CLIENT, INSERT_WR_WP } from '~/queries/clients'

export const useClientsStore = defineStore('clients', () => {
  const { query, mutate } = useGqlMikro()

  const client = ref<RecyclerGraphql | ProducerGraphql>({} as RecyclerGraphql | ProducerGraphql)
  const clients = ref<RecyclerGraphql[] | ProducerGraphql[]>([])

  const filters = reactive({
    search: '',
  })

  watch(filters, async () => {
    await fetchClients()
  }, { deep: true })

  const isRecycler = computed(() => {
    return client.value?.__typename === 'RecyclerGraphql'
  })

  const isProducer = computed(() => {
    return client.value?.__typename === 'ProducerGraphql'
  })

  async function setCurrentClient(clientId: string | string[]) {
    if (clients.value.length === 0) {
      await fetchClients()
    }

    const clientFound = clients.value.find((c: Maybe<ClientGraphql>) => c && c.id === clientId)
    if (clientFound) {
      client.value = clientFound
    }
  }

  async function createClient(input: CreateClientInput, wr_wp: [], isRecycler: boolean): Promise<ClientGraphql> {
    const { mutate } = useGqlMikro()

    const { data, validationError, errors } = await mutate({
      mutation: CREATE_CLIENT,
      variables: { input },
    })

    const dataToSend = wr_wp.map((item: any) => ({
      wr_id: isRecycler ? data?.createClient.id : item.wr_id,
      wp_id: isRecycler ? item.wp_id : data?.createClient.id,
    }))

    const relationWPWR = await apolloClient.mutate({
      mutation: INSERT_WR_WP,
      variables: { objects: dataToSend },
    })

    return data?.createClient as ClientGraphql
  }

  async function fetchClients(): Promise<void> {
    const clientFilters: ClientFilterGraphql = {}
    if (filters.search)
      clientFilters.search = filters.search
    const { data } = await query({
      query: GET_CLIENTS,
      variables: {
        pagination: { limit: 1000, offset: 0 },
        filters: clientFilters,
      } as QueryClientsArgs,
    })

    // ! Without this, if froze the array, apollo caching is freezing the data
    clients.value = JSON.parse(JSON.stringify(data?.clients?.collection)) as Array<ClientGraphql> || []
  }

  async function updateClient(input: ClientInput): Promise<{ validationError: string | null | undefined }> {
    const inputData = omitBy(input, v => v === null)

    const { data, validationError } = await mutate({
      mutation: UPDATE_CLIENT,
      variables: { input: inputData },
    })

    if (data) {
      clients.value.splice(clients.value.findIndex(c => c.id === client.value.id), 1, data!.updateClient)
      client.value = data!.updateClient
    }

    return { validationError }
  }

  return {
    clients,
    filters,
    client,
    isRecycler,
    isProducer,
    setCurrentClient,
    fetchClients,
    createClient,
    updateClient,
  }
})
