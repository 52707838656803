<script setup lang="ts">
const { t } = useI18n()

const { user, isAdmin, isProducer, isRecycler } = storeToRefs(useUsersStore())
const { collectsCount, page } = storeToRefs(useCollectsStore())

const loading = ref<boolean>(false)
</script>

<template>
  <HeaderMobile :title="t('global.services')" class="md:hidden" />
  <div
    v-if="isAdmin || user?.user.role === 'user'"
    class="flex flex-1 flex-col bg-tertiary text-primary overflow-auto"
    :class="`min-h-[calc(${isAdmin ? '100vh-50px' : '100vh'})]`"
  >
    <div v-if="!loading" class="flex flex-col grow overflow-y-auto p-2 md:p-4">
      <div v-if="isAdmin" class="flex flex-col grow overflow-auto">
        <Services class="overflow-y-auto flex grow" />
        <OPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" class="pt-2" />
      </div>
      <ServicesRecycler v-else-if="isRecycler" />
      <ServicesProducer v-else-if="isProducer" />
    </div>
    <Loader v-else />
  </div>
  <div v-else class="w-full flex justify-center pt-4 text-red">
    <div>Unauthorized</div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
