import _ from 'lodash'
import { defineStore } from 'pinia'
import apolloClient from '~/composables/graphql'
import {
  SITES_QUERY_INSERT,
  SITES_QUERY_LIST,
  SITES_QUERY_LIST_WITH_RECYCLER,
  SITES_QUERY_UPDATE,
} from '~/queries/sites'

export const sitesStore = defineStore({
  id: 'sites',
  state: () => ({
    sites: {
      list: [] as Array<any>,
    },
  }),
  getters: {
    getSites(): any[] {
      return this.sites.list
    },
    getSitesAsOptions(): any[] {
      const options = this.sites.list.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
      return options
    },
  },
  actions: {
    getSite(siteId: string): any {
      return this.sites.list.find((item: any) => item.id.toString() === siteId.toString())
    },
    getSitesByClient(clientId: number): any[] {
      return this.sites.list.filter((item: any) => item.client_id === clientId)
    },
    loadSiteByClientId(clientId: number): any {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return

      const sites = this.sites.list.filter((item: any) => item?.client_id == clientId)
      return sites
    },
    async loadSites(): Promise<any> {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return

      this.sites.list = []
      const site = await apolloClient.query({
        query: SITES_QUERY_LIST,
      })
      const list = new Array<any>()
      for (const item of site.data.sites) {
        const client = item.client
        const site = _.cloneDeep(item)
        if (client) {
          site.client_name = client.name
          site.client_id = client.id
        }
        list.push(site)
      }
      this.sites.list = list
    },
    async loadSite(): Promise<any> {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return
    },
    async createSite(site: any): Promise<any> {
      const { user } = storeToRefs(useUsersStore())

      if (!user.value)
        return

      const sites = await apolloClient.mutate({
        mutation: SITES_QUERY_INSERT,
        variables: {
          object: site,
        },
      })
      this.sites.list.push(site)
      return sites
    },
    async updateSite(site: any): Promise<any> {
      const { user } = storeToRefs(useUsersStore())

      if (!user.value)
        return

      delete site.__typename
      await apolloClient.mutate({
        mutation: SITES_QUERY_UPDATE,
        variables: {
          id: site.id,
          object: site,
        },
      })

      const index = this.sites.list.findIndex(item => item.id === site.id)
      this.sites.list[index] = site
    },
    async getUserSitesWithRecycler(): Promise<any> {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return

      const site = await apolloClient.query({
        query: SITES_QUERY_LIST_WITH_RECYCLER,
      })
      return site.data.sites.filter((item: any) => item.client_id === user.value.client.id)
    },
  },
})
