import { signInWithEmailAndPassword } from 'firebase/auth'
import { loadStores } from '~/controllers/stores'
import { fb_auth, on_auth_state_changed } from '~/services/firebase'

let unsubscribe: any = {}

export async function loadUser(): Promise<any> {
  const { getCurrentUser } = useUsersStore()
  const { stores_loaded } = storeToRefs(useUsersStore())
  unsubscribe = on_auth_state_changed(fb_auth, async (user: any) => {
    if (user) {
      const userId: string = user?.uid ? user?.uid : ''
      await getCurrentUser()
      await loadStores(userId)
    }
    else {
      stores_loaded.value = true
    }
  })
}

export async function getUserById(idUser: string) {
  const user = {} // await dbHelper.getDocFromCollection('users', idUser)
  return user
}

// export const getOrg = async(userId: string) => {
//   const user = await dbHelper.getDocFromCollection(USER_COLLECTION_NAME, userId)
//   const clientId = user?.client_id
//   const client = await dbHelper.getDocFromCollection(CLIENT_COLLECTION_NAME, clientId)
//   const slug = client?.slug ?? 'default'
//   return slug
// }

export function getUserId() {
  const user = fb_auth.currentUser
  if (user) {
    const userId: string = user?.uid ? user?.uid : ''
    return userId
  }
  return ''
}

export async function getUsers(client_id: string): Promise<any[]> {
  return [] // await dbHelper.getAllDataFromCollectionWithWhere('clients', 'client_id', client_id)
}

export async function signIn(email: string, password: string) {
  await signInWithEmailAndPassword(fb_auth, email, password)
  await loadUser()
}
export async function logout() {
  const { resetFilters } = useCollectsStore()
  resetFilters()
  unsubscribe()
  await fb_auth.signOut()
}
