import { provideApolloClient } from '@vue/apollo-composable'
import _ from 'lodash'
import { defineStore } from 'pinia'
import {
  GET_CONTAINERS_TYPES,
  RAW_MATERIALS_CONTAINERS_DATA_QUERY_INSERT,
  RAW_MATERIALS_DATA_QUERY_DELETE,
  RAW_MATERIALS_DATA_QUERY_LIST,
  RAW_MATERIALS_DATA_QUERY_UPDATE,
  RAW_MATERIALS_QUERY_DELETE_BY_MATERIAL_ID,
} from '~/queries/raw-materials-containers'
import apolloClient from '../composables/graphql'

provideApolloClient(apolloClient)

export const rawMaterialsContainersStore = defineStore({
  id: 'rawMaterialsContainers',
  state: () => ({
    rawMaterialsContainers: {
      list: [] as Array<any>,
    },
  }),
  getters: {
    getRawMaterialsContainers(): any[] {
      return this.rawMaterialsContainers.list
    },
    getRawMaterialsContainersAsOptions(): any[] {
      const options = this.rawMaterialsContainers.list.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
      return options
    },
  },
  actions: {
    getRawMaterialsContainersById(rawMaterialContainersId: string): any {
      return this.rawMaterialsContainers.list.find(item => item.id.toString() === rawMaterialContainersId.toString())
    },
    async loadRawMaterialsContainers(): Promise<any> {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return

      const rawMaterialsContainers: { data: { raw_materials_containers: any } } = await apolloClient.query({
        query: RAW_MATERIALS_DATA_QUERY_LIST,
      })

      this.rawMaterialsContainers.list = rawMaterialsContainers.data?.raw_materials_containers

      return rawMaterialsContainers
    },

    loadRawMaterialContainersByMaterialId(rawMaterialId: string): any {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return

      return this.rawMaterialsContainers.list.filter(item => item.raw_materials_id === rawMaterialId)
    },

    loadRawMaterialContainerByClientId(clientId: string): any {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return

      return this.rawMaterialsContainers.list.filter(item => item.client_id === clientId)
    },

    async createRawMaterialsContainers(rawMaterial: any): Promise<any> {
      const { user } = storeToRefs(useUsersStore())

      if (!user.value)
        return

      const container = await apolloClient.mutate({
        mutation: RAW_MATERIALS_CONTAINERS_DATA_QUERY_INSERT,
        variables: { object: rawMaterial },
      })

      this.rawMaterialsContainers.list = [
        ...this.rawMaterialsContainers.list,
        container.data.insert_raw_materials_containers_one,
      ]
      return container
    },
    async updateRawMaterialsContainers(rawMaterial: any): Promise<any> {
      const { user } = storeToRefs(useUsersStore())

      if (!user.value)
        return
      const propertiesToRemove = [
        '__typename',
        '_created_at',
        '_created_by',
        '_updated_at',
        '_updated_by',
        'raw_material',
        'site',
      ]

      const rawMaterialToUpdate = this.removeProperties(rawMaterial, propertiesToRemove)
      const rawMaterialContainer = await apolloClient.mutate({
        mutation: RAW_MATERIALS_DATA_QUERY_UPDATE,
        variables: { id: rawMaterial.id, object: rawMaterialToUpdate },
      })
      const index = _.findIndex(this.rawMaterialsContainers.list, { id: rawMaterial.id })
      if (index !== -1) {
        this.rawMaterialsContainers.list = [
          ...this.rawMaterialsContainers.list.slice(0, index),
          rawMaterialContainer.data.update_raw_materials_containers_by_pk,
          ...this.rawMaterialsContainers.list.slice(index + 1),
        ]
      }
      return rawMaterialContainer
    },

    async deleteRawMaterialsContainer(rawMaterialContainerId: any): Promise<any> {
      const { user } = storeToRefs(useUsersStore())

      if (!user.value)
        return
      await apolloClient.mutate({
        mutation: RAW_MATERIALS_DATA_QUERY_DELETE,
        variables: { id: rawMaterialContainerId },
      })

      this.rawMaterialsContainers.list = this.rawMaterialsContainers.list.filter((container) => {
        container.id != rawMaterialContainerId
      })
    },

    async deleteRawMaterialsContainerByMaterialId(materialId: any): Promise<any> {
      const { user } = storeToRefs(useUsersStore())

      if (!user.value)
        return
      await apolloClient.mutate({
        mutation: RAW_MATERIALS_QUERY_DELETE_BY_MATERIAL_ID,
        variables: { id: materialId },
      })
    },

    removeProperties(obj: Record<string, any>, propsToRemove: string[]): Record<string, any> {
      const clonedObj = _.cloneDeep(obj)
      propsToRemove.forEach(prop => delete clonedObj[prop])
      return clonedObj
    },

    // RECYCLER
    async loadRawMaterialContainersByMaterialIdAndSiteId(rawMaterialId: string, siteId: string): Promise<any> {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return

      const RMCByMaterialAndSiteId = this.rawMaterialsContainers.list.filter(
        item => item.raw_materials_id === rawMaterialId && item.site_id === siteId,
      )
      return RMCByMaterialAndSiteId
    },

    async getContainersTypes(): Promise<[] | void> {
      const { user } = storeToRefs(useUsersStore())
      if (!user.value)
        return
      const containers = await apolloClient.query({
        query: GET_CONTAINERS_TYPES,
      })
      return containers.data.standard_containers
    },
  },
})
