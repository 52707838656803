<script setup lang="ts">
import { BoxIcon, ChevronDownIcon } from 'lucide-vue-next'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  options: {
    type: Array as PropType<any[]>,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  prefixIcon: {
    type: Function,
  },
  label: {
    type: String,
  },
  suffixIcon: {
    type: Function,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  keyForLabel: {
    type: String,
    default: 'label',
  },
  outerClasses: {
    type: String,
    default: '',
  },
  keyForValue: {
    type: String,
    default: 'value',
  },
  showDocument: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['change'])

const model = defineModel()
const { keyForLabel, keyForValue, id, disabled, options, error } = toRefs(props)
const isOpen = ref(false)
const selectedOption = ref<any>(null)

const containerClasses = computed(() => [
  'relative flex items-center rounded bg-base-200',
  'border border-gray-300',
  {
    'border-red-500': error.value,
    'border-green-500': !error.value && model.value,
    'opacity-60 cursor-not-allowed bg-neutral-100': disabled.value,
  },
])

const inputClasses = computed(() => [
  'w-full px-3 py-1 h-8 text-slate-800 text-sm rounded',
  'bg-transparent border-none focus:outline-none focus:ring-0',
  'flex justify-between items-center',
  { 'cursor-not-allowed': disabled.value },
])

const formattedLabel = computed(() => {
  return selectedOption.value ? getOptionLabel(selectedOption.value) : getOptionLabel(model.value)
})

function toggleDropdown() {
  isOpen.value = !isOpen.value
}
function closeDropdown() {
  isOpen.value = false
}

function getOptionLabel(option: any): string {
  if (typeof option === 'object' && option !== null) {
    return option[keyForLabel.value]
  }
  const opt = options.value.find(opt => getOptionValue(opt) === option)
  if (opt)
    return opt[keyForLabel.value] || String(opt)
  return String(option)
}

function getOptionValue(option: any): string {
  if (typeof option === 'object' && option !== null) {
    return option[keyForValue.value]
  }
  return String(option)
}

function selectOption(option: any) {
  model.value = typeof option === 'object' && option !== null ? option[keyForValue.value] : option
  selectedOption.value = option
  isOpen.value = false
  emit('change', model.value)
}

onMounted(() => {
  nextTick(() => {
    if (model.value || model.value === 0) {
      // @ts-expect-error double equal is needed for conversion id of type number to model.value of type string
      selectedOption.value = options.value.find(option => getOptionValue(option) == model.value)
      emit('change', model.value)
    }
  })
})
// Custom directive for detecting clicks outside the component
const vClickOutside = {
  mounted(el: any, binding: any) {
    el.clickOutsideEvent = (event: Event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event)
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el: any) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
}
</script>

<template>
  <div v-click-outside="closeDropdown" class="relative w-full" :class="outerClasses">
    <label :for="id" class="label">
      <span class="label-text">{{ label }}</span>
    </label>
    <div :class="containerClasses">
      <button
        class="text-left focus:border-[#14B8A6] focus:ring-[#14B8A6] focus:ring-1"
        :class="[inputClasses, { disabled }]"
        v-bind="$attrs"
        type="button"
        @click="toggleDropdown"
      >
        <div class="flex space-x-2">
          <component :is="prefixIcon" v-if="prefixIcon" :size="18" />
          <div v-if="showDocument" class="flex items-center space-x-2">
            <img v-if="selectedOption?.documents?.collection[0]?.signedUrl" :src="selectedOption.documents.collection[0].signedUrl" alt="document" class="h-5 w-5 rounded-md">
            <BoxIcon v-else :size="18" />
          </div>
          <span v-if="selectedOption" class="text-sm text-slate-900">{{ formattedLabel }}</span>
          <span v-else class="text-sm text-slate-400">{{ placeholder }}</span>
        </div>
        <ChevronDownIcon :size="16" :class="{ 'transform rotate-180': isOpen }" />
      </button>
    </div>
    <ul
      v-if="isOpen && options.length > 0"
      class="absolute z-10 w-full my-1 overflow-auto bg-white border rounded-md shadow-lg max-h-60 focus:outline-none"
    >
      <li
        v-for="option in options"
        :key="option[keyForValue]"
        class="flex items-center text-sm text-slate-800 px-4 py-2 cursor-pointer hover:bg-[#14B8A6]/20"
        @click="selectOption(option)"
      >
        <slot name="option" :option="option">
          <div class="space-x-2 flex grow">
            <div v-if="showDocument">
              <img v-if="option?.documents?.collection[0]?.signedUrl" :src="option.documents.collection[0].signedUrl" alt="document" class="h-5 w-5 rounded-md">
              <BoxIcon v-else :size="18" />
            </div>
            <span>{{ getOptionLabel(option) }}</span>
            <div class="flex grow justify-end">
              <slot name="additional-info" :option="option" />
            </div>
          </div>
        </slot>
      </li>
    </ul>
    <label v-if="error" class="label">
      <span class="label-text-alt text-error">{{ error }}</span>
    </label>
  </div>
</template>
