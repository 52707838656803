import * as Sentry from '@sentry/vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import VueDatePicker from '@vuepic/vue-datepicker'
import { VERSION } from '@wastex/common'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { createApp, h, provide } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import apolloClient from '~/composables/graphql'
import App from './App.vue'
import '@unocss/reset/tailwind.css'
import './styles/main.css'
import 'uno.css'

const routes = setupLayouts(generatedRoutes)

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App),
})

app.component('VueDatePicker', VueDatePicker)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

export function getEnvironment() {
  const hostname = window.location.hostname
  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return 'development'
  }
  else if (hostname.includes('staging')) {
    return 'staging'
  }
  else {
    return 'production'
  }
}

const environment = getEnvironment()
if (environment !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://09a42355a2adea643bfa0d5231fadfa5@o4507893276475392.ingest.de.sentry.io/4507893278703696',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [
      'https://graphql-engine-xtq7ravppq-ew.a.run.app/v1/graphql',
      'https://graphql-engine-xmq7eperwq-ew.a.run.app/v1/graphql',
      'https://node-nest-api-xtq7ravppq-ew.a.run.app/graphql',
      'https://node-nest-api-xmq7eperwq-ew.a.run.app/graphql',
      'https://staging.wastex.tech',
      'https://app.wastex.tech',
    ],
    environment,
    release: VERSION,
    tracesSampleRate: environment === 'production' ? 0.1 : 1.0,
    replaysSessionSampleRate: environment === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
  })
}

app.use(router)

const isClient = true
const initialState = {}

const ctx: any = { app, router, isClient, initialState }

const modules = import.meta.glob('./modules/*.ts', { eager: true })
Object.values(modules).forEach(
  (m: any) => {
    m.install?.(ctx)
  },
)

app.mount('#app')
